import {AbstractEnum} from "@/models/enums/AbstractEnum";

export class Role extends AbstractEnum {

    public static ADMIN = new Role("ADMIN", "Administrator");
    public static IMPLEMENTER = new Role("IMPLEMENTER", "Wdrożeniowiec");

    static of(name: string): Role {
        return Role[name];
    }

    static isIn(list: Array<Role>, target: Role) : boolean {

        if(!target){
            return false;
        }

        for(let item of list){
            if(item.name === target.name){
                return true;
            }
        }

        return false;

    }

    static list() {
        return Array.of(this.ADMIN, this.IMPLEMENTER);
    }

}
