import {User} from "@/models/User";
import {restService} from "@/services";
import {Endpoints} from "@/endpoints";
import {Role} from "@/models/enums/Role";
import {UpdateUserRequest} from "@/models/request/UpdateUserRequest";
import {UpdateProfileRequest} from "@/models/request/UpdateProfileRequest";
import {ChangePasswordRequest} from "@/models/request/ChangePasswordRequest";
import {Message} from "@/models/Message";

export class ProfileService {

    async getProfile(): Promise<User> {
        let profile: User = await restService.get(Endpoints.getProfile);
        return profile ? this.transform(profile) : null;
    }

    async updateProfile(updateProfileRequest: UpdateProfileRequest): Promise<Message | User> {
        return await restService.put<Message | User>(Endpoints.updateProfile, updateProfileRequest);
    }

    async changePassword(changePasswordRequest: ChangePasswordRequest): Promise<Message> {
        return await restService.put<Message>(Endpoints.changePassword, changePasswordRequest);
    }

    transform(user: User): User {
        user.role = Role.of(<string>user.role);
        return user;
    }

}
