export class Endpoints {

    static login: string = `${process.env.VUE_APP_API_URL}/auth/login`;
    static getSession: string = `${process.env.VUE_APP_API_URL}/auth/session`;
    static refreshSession: string = `${process.env.VUE_APP_API_URL}/auth/session-refresh/:refreshToken`;

    static getProfile: string = `${process.env.VUE_APP_API_URL}/auth/profile`;
    static updateProfile: string = `${process.env.VUE_APP_API_URL}/auth/profile`;
    static changePassword: string = `${process.env.VUE_APP_API_URL}/auth/change-password`;

    static getUsers: string = `${process.env.VUE_APP_API_URL}/users`;
    static getImplementers: string = `${process.env.VUE_APP_API_URL}/users/implementers`;
    static createUser: string = `${process.env.VUE_APP_API_URL}/users`;
    static updateUser: string = `${process.env.VUE_APP_API_URL}/users/:id`;
    static usernameExists: string = `${process.env.VUE_APP_API_URL}/users/exists/username/:username`;
    static emailExists: string = `${process.env.VUE_APP_API_URL}/users/exists/email/:email`;
    static changeUserPassword: string = `${process.env.VUE_APP_API_URL}/users/change-password/:id`;

    static getOrders: string = `${process.env.VUE_APP_API_URL}/orders`;
    static getOrder: string = `${process.env.VUE_APP_API_URL}/orders/:id`;
    static updateOrder: string = `${process.env.VUE_APP_API_URL}/orders/:id`;
    static createOrder: string = `${process.env.VUE_APP_API_URL}/orders`;
    static systemAddressExists: string = `${process.env.VUE_APP_API_URL}/orders/exists/system-address/:systemAddress`;
    static updateOrderStatus: string = `${process.env.VUE_APP_API_URL}/orders/status/:id/:newStatus`;
    static updateOrderStatusWithReason: string = `${process.env.VUE_APP_API_URL}/orders/status/reason/:id/:newStatus`;

    static getSettings: string = `${process.env.VUE_APP_API_URL}/settings`;
    static updateSettings: string = `${process.env.VUE_APP_API_URL}/settings`;

}
