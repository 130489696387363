import {UserNotificationType} from "@/models/UserNotificationType";

export class UserNotification {

    type: UserNotificationType;
    message: string;

    constructor(type: UserNotificationType, message: string) {
        this.type = type;
        this.message = message;
    }

}
