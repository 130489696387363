import {AbstractEnum} from "@/models/enums/AbstractEnum";

export default class Infrastructure extends AbstractEnum {

    public static VULCAN = new Infrastructure("VULCAN", "Vulcan");
    public static CLIENT = new Infrastructure("CLIENT", "Klient");

    static of(name: string): Infrastructure {
        return Infrastructure[name];
    }

    static isIn(list: Array<Infrastructure>, target: Infrastructure) : boolean {

        for(let item of list){
            if(item.name === target.name){
                return true;
            }
        }

        return false;

    }

    static list(): Array<Infrastructure> {
        return [this.VULCAN, this.CLIENT];
    }

}
