import {AbstractEnum} from "@/models/enums/AbstractEnum";

export class Voivodeship extends AbstractEnum {

    public static WIELKOPOLSKIE = new Voivodeship('WIELKOPOLSKIE', 'Wielkopolskie');
    public static KUJAWSKO_POMORSKIE = new Voivodeship('KUJAWSKO_POMORSKIE', 'Kujawsko-pomorskie');
    public static MALOPOLSKIE = new Voivodeship('MALOPOLSKIE', 'Małopolskie');
    public static LODZKIE = new Voivodeship('LODZKIE', 'Łódzkie');
    public static DOLNOSLASKIE = new Voivodeship('DOLNOSLASKIE', 'Dolnośląskie');
    public static LUBELSKIE = new Voivodeship('LUBELSKIE', 'Lubelskie');
    public static LUBUSKIE = new Voivodeship('LUBUSKIE', 'Lubuskie');
    public static MAZOWIECKIE = new Voivodeship('MAZOWIECKIE', 'Mazowieckie');
    public static OPOLSKIE = new Voivodeship('OPOLSKIE', 'Opolskie');
    public static PODLASKIE = new Voivodeship('PODLASKIE', 'Podlaskie');
    public static POMORSKIE = new Voivodeship('POMORSKIE', 'Pomorskie');
    public static SLASKIE = new Voivodeship('SLASKIE', 'Śląskie');
    public static PODKARPACKIE = new Voivodeship('PODKARPACKIE', 'Podkarpackie');
    public static SWIETOKRZYSKIE = new Voivodeship('SWIETOKRZYSKIE', 'Świętokrzyskie');
    public static WARMINSKO_MAZURSKIE = new Voivodeship('WARMINSKO_MAZURSKIE', 'Warmińsko-Mazurskie');
    public static ZACHODNIOPOMORSKIE = new Voivodeship('ZACHODNIOPOMORSKIE', 'Zachodniopomorskie');

    static of(name: string): Voivodeship {
        return Voivodeship[name];
    }

    static isIn(list: Array<Voivodeship>, target: Voivodeship) : boolean {

        for(let item of list){
            if(item.name === target.name){
                return true;
            }
        }

        return false;

    }

    static list(): Array<Voivodeship> {
        return [
            this.WIELKOPOLSKIE,
            this.KUJAWSKO_POMORSKIE,
            this.MALOPOLSKIE ,
            this.LODZKIE ,
            this.DOLNOSLASKIE,
            this.LUBELSKIE ,
            this.LUBUSKIE ,
            this.MAZOWIECKIE ,
            this.OPOLSKIE ,
            this.PODLASKIE ,
            this.POMORSKIE ,
            this.SLASKIE ,
            this.PODKARPACKIE,
            this.SWIETOKRZYSKIE ,
            this.WARMINSKO_MAZURSKIE,
            this.ZACHODNIOPOMORSKIE
        ];
    }


}
