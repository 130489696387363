import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import {Role} from "@/models/enums/Role";
import {sessionService} from "@/services";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    meta: {
      roles: [Role.ADMIN, Role.IMPLEMENTER]
    },
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/403',
    name: 'Unauthorized',
    component: () => import(/* webpackChunkName: "unauthorized" */ '../views/Unauthorized.vue')
  },
  {
    path: '/orders',
    name: 'Orders',
    meta: {
      roles: [Role.ADMIN, Role.IMPLEMENTER]
    },
    component: () => import(/* webpackChunkName: "orders" */ '../views/Orders.vue')
  },
  {
    path: '/users',
    name: 'Users',
    meta: {
      roles: [Role.ADMIN]
    },
    component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    meta: {
      roles: [Role.ADMIN]
    },
    component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: {
      roles: [Role.ADMIN, Role.IMPLEMENTER]
    },
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue')
  },
  {
    path: '/help',
    name: 'Help',
    meta: {
      roles: [Role.ADMIN, Role.IMPLEMENTER]
    },
    component: () => import(/* webpackChunkName: "help" */ '../views/Help.vue')
  },
  {
    path: '/lock',
    name: 'Lock',
    component: () => import(/* webpackChunkName: "lock" */ '../views/Lock.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {

  await sessionService.refresh();

  if(await sessionService.isLogged()){

    if(to.name === 'Login'){
      next('/');
    }else if(to.name !== 'Lock' && await sessionService.isSystemBlockedForCurrentUser()){
      next('/lock');
    }else if(to.meta.roles && !sessionService.hasPermissions(to)) {
      next('/403');
    }else{
      next()
    }

  }else if(to.meta.roles && !sessionService.hasPermissions(to)){
    next('/login');
  }else{
    next()
  }

});

export default router
