import Vue from "vue";
import {sessionService} from "@/services";
import {EventBus} from "@/main";
import {UserNotification} from "@/models/UserNotification";
import {UserNotificationType} from "@/models/UserNotificationType";
import {AxiosRequestConfig} from "axios";
import {SortAndFilterOptions} from "@/models/SortAndFilterOptions";
import {Page} from "@/models/Page";

export class RestService {

    headers: object = {
        'Content-Type': 'application/json'
    };

    private getHeaders(): object {
        this.headers['Sww-Session'] = sessionService.getAccessToken() || '';
        return this.headers;
    }

    private getConfig(sortAndFilterOptions?: SortAndFilterOptions) : AxiosRequestConfig {

        return {
            headers: this.getHeaders(),
            params: sortAndFilterOptions ? sortAndFilterOptions : {}
        } as AxiosRequestConfig;

    }

    private static async handleError(error: any): Promise<void> {

        let message: string = error.response ? error.response.data.message : null;
        let status: number = error.response ? error.response.status : null;

        switch (status) {
            case 401:

                if (window.location.pathname !== '/login') {
                    window.location.href = '/login';
                }

                break;
            case 500:
                EventBus.$emit('sent-notification', new UserNotification(UserNotificationType.ERROR, message));
                break;
        }

        return null;

    }

    async delete<T>(endpoint: string): Promise<T> {

        try {
            let response: any = await Vue.axios.delete(endpoint, this.getConfig());
            return response.data;
        } catch (error) {
            await RestService.handleError(error);
        }

        return null;

    }

    async put<T>(endpoint: string, request: Request): Promise<T> {

        try {
            let response: any = await Vue.axios.put(endpoint, request, this.getConfig());
            return response.data;
        } catch (error) {
            await RestService.handleError(error);
        }

        return null;

    }

    async post<T>(endpoint: string, request: Request): Promise<T> {

        try {
            let response: any = await Vue.axios.post(endpoint, request, this.getConfig());
            return response.data;
        } catch (error) {
            await RestService.handleError(error);
        }

        return null;

    }

    async get<T>(endpoint: string): Promise<T> {

        try {
            let response: any = await Vue.axios.get(endpoint, this.getConfig());
            return response.data;
        } catch (error) {
            await RestService.handleError(error);
        }

        return null;

    }

    async listSimple<T>(endpoint: string): Promise<Array<T>> {

        try {
            let response: any = await Vue.axios.get(endpoint, this.getConfig());
            return response.data;
        } catch (error) {
            await RestService.handleError(error);
        }

        return null;

    }

    async list<T>(endpoint: string, sortAndFilterOptions: SortAndFilterOptions): Promise<Page<T>> {

        try {
            let response: any = await Vue.axios.get(endpoint, this.getConfig(sortAndFilterOptions));
            return response.data;
        } catch (error) {
            await RestService.handleError(error);
        }

        return null;

    }

}

export interface Request {
}
