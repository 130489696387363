export class AbstractEnum {

    readonly name: string;
    readonly label: string;

    constructor(name: string, label: string) {
        this.name = name;
        this.label = label;
    }

    is(role: AbstractEnum): boolean {
        return this.name === role.name;
    }

}
