import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import axios from 'axios'
import VueAxios from 'vue-axios'
import Vue2Editor from "vue2-editor";
import VueCookies from 'vue-cookies'

Vue.config.productionTip = false

Vue.use(VueAxios, axios)
// @ts-ignore
Vue.use(Vue2Editor);
Vue.use(VueCookies)

export const EventBus = new Vue();

new Vue({
  router,
  vuetify,
  render: (h: Function) => h(App)
}).$mount('#app');
