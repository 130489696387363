import Order from "@/models/Order";
import {Endpoints} from "@/endpoints";
import {restService} from "@/services";
import OrderStatus from "@/models/enums/OrderStatus";
import Infrastructure from "@/models/enums/Infrastructure";
import {Voivodeship} from "@/models/enums/Voivodeship";
import {Page} from "@/models/Page";
import {SortAndFilterOptions} from "@/models/SortAndFilterOptions";
import {CreateOrEditOrderRequest} from "@/models/request/CreateOrEditOrderRequest";

export class OrderService {

    transformSingle(order: Order): Order {
        order.status = OrderStatus.of(<string>order.status);
        order.infrastructure = Infrastructure.of(<string>order.infrastructure);
        order.voivodeship = Voivodeship.of(<string>order.voivodeship);
        return order;
    }

    transform(orders: Page<Order>): Page<Order> {

        for (let order of orders.content) {
            order = this.transformSingle(order);
        }

        return orders;

    }

    async list(sortAndFilterOptions: SortAndFilterOptions): Promise<Page<Order>> {
        let orders: Page<Order> = await restService.list<Order>(Endpoints.getOrders, sortAndFilterOptions);
        return this.transform(orders);
    }

    async getOrder(id: number): Promise<Order> {
        return await restService.get(Endpoints.getOrder.replace(':id', `${id}`));
    }

    async createOrder(createOrEditOrderRequest: CreateOrEditOrderRequest): Promise<Order> {
        createOrEditOrderRequest.candidatesCount = parseInt(<string>createOrEditOrderRequest.candidatesCount);

        if(createOrEditOrderRequest.infrastructure === Infrastructure.VULCAN.name){
            createOrEditOrderRequest.systemAddress = createOrEditOrderRequest.systemAddress + '.edu.com.pl';
        }

        return await restService.post<Order>(Endpoints.createOrder, createOrEditOrderRequest);
    }

    async updateOrder(id: number, createOrEditOrderRequest: CreateOrEditOrderRequest): Promise<Order> {

        createOrEditOrderRequest.candidatesCount = parseInt(<string>createOrEditOrderRequest.candidatesCount);

        if(createOrEditOrderRequest.infrastructure === Infrastructure.VULCAN.name){
            createOrEditOrderRequest.systemAddress = createOrEditOrderRequest.systemAddress + '.edu.com.pl';
        }

        return await restService.put<Order>(Endpoints.updateOrder.replace(':id', `${id}`), createOrEditOrderRequest);
    }

    async updateOrderStatus(id: number, orderStatus: OrderStatus): Promise<void> {
        await restService.put(Endpoints.updateOrderStatus.replace(':id', `${id}`).replace(':newStatus', `${orderStatus.name}`), null);
    }

    async updateOrderStatusWithReason(id: number, orderStatus: OrderStatus, reason: string): Promise<void> {
        await restService.put(Endpoints.updateOrderStatusWithReason.replace(':id', `${id}`).replace(':newStatus', `${orderStatus.name}`), { reason: reason });
    }

    async systemAddressExists(systemAddress: string): Promise<boolean> {
        return (await restService.get(Endpoints.systemAddressExists.replace(':systemAddress', `${systemAddress || 'null'}`)))['message'] === 'true';
    }

}
