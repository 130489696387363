import {OrderService} from "@/services/OrderService";
import {RestService} from "@/services/RestService";
import {ProfileService} from "@/services/ProfileService";
import {SessionService} from "@/services/SessionService";
import {SettingsService} from "@/services/SettingsService";
import UserService from "@/services/UserService";

export const restService = new RestService();
export const orderService = new OrderService();
export const profileService = new ProfileService();
export const sessionService = new SessionService();
export const settingsService = new SettingsService();
export const userService = new UserService();
