





























import {Component, Vue} from 'vue-property-decorator';
import {EventBus} from "@/main";
import {UserNotification} from "@/models/UserNotification";
import {UserNotificationType} from "@/models/UserNotificationType";

@Component({
  components: {},
})
export default class UserNotificationComponent extends Vue {

  information: boolean = false;
  error: boolean = false;

  notification: UserNotification = {} as UserNotification;

  created(): void {

    EventBus.$on('send-notification', (notification: UserNotification) => {
      this.notification = notification;

      if(this.notification.type === UserNotificationType.ERROR){
        this.error = true;
      }else{
        this.information = true;
      }

    });

  }

}
