import UserSession from "@/models/UserSession";
import LoginRequest from "@/models/request/LoginRequest";
import {Role} from "@/models/enums/Role";
import {Route} from "vue-router";
import {restService, settingsService} from "@/services";
import {Endpoints} from "@/endpoints";
import Vue from "vue";
import {EventBus} from "@/main";
import router from "@/router";
import {Settings} from "@/models/Settings";
import {Message} from "@/models/Message";
import {User} from "@/models/User";

export class SessionService {

    private performKeepAlive: boolean = false;
    private session: UserSession = null;

    constructor() {
        this.keepAlive();
    }

    async keepAlive(): Promise<void> {
        await this.refresh();
        setTimeout(this.keepAlive.bind(this), 1000 * 60);
    }

    async logout(): Promise<void> {
        this.removeSessionKey();
        this.session = null;
        this.performKeepAlive = false;
        EventBus.$emit('sessionChange', this.session);
        await router.push({ path: '/login' });
    }

    async login(loginRequest: LoginRequest): Promise<Message> {

        let sessionOrMessage: UserSession | Message = await restService.post(Endpoints.login, loginRequest);

        if(sessionOrMessage != null && (<UserSession>sessionOrMessage).refreshToken){
            this.session = this.transform(<UserSession>sessionOrMessage);
            this.saveSession();
            EventBus.$emit('sessionChange', this.session);
            this.performKeepAlive = true;
            return new Message();
        }

        return <Message>sessionOrMessage;

    }

    transform(session: UserSession): UserSession {
        session.role = Role.of(<string>session.role);
        return session;
    }

    async refresh(): Promise<void> {

        if(this.session != null){
            let session: UserSession = await restService.put(Endpoints.refreshSession.replace(':refreshToken', this.session.refreshToken), {} as Request);
            this.session.accessToken = session.accessToken;
            this.saveSession();
        }

    }

    async getSession(): Promise<UserSession> {

        if (this.session === null && this.getAccessToken()) {
            let session: UserSession = await restService.get(Endpoints.getSession);

            if(session != null){
                this.session = this.transform(session);
                EventBus.$emit('sessionChange', this.session);
            }

        }

        //get session from API if not present here
        return this.session;
    }

    getAccessToken(): string {
        let session: UserSession = Vue.$cookies.get('swwsession');

        if(session != null){
            return session.accessToken;
        }

        return null;

    }

    saveSession(): void {

        if(this.session){
            Vue.$cookies.set('swwsession', this.session, 0);
        }

    }

    removeSessionKey(): void {
        Vue.$cookies.remove('swwsession');
    }

    hasPermissions(route: Route) {

        if(this.session == null){
            return false;
        }

        return (route.meta.roles === undefined || route.meta.roles.length === 0) || Role.isIn(route.meta.roles, <Role>this.session.role);
    }

    async isNotLogged(): Promise<boolean> {
        return !(await this.isLogged());
    }

    async isLogged(): Promise<boolean> {
        await this.getSession();
        return this.session !== null;
    }

    async isSystemBlockedForCurrentUser(): Promise<boolean> {

        if((<Role>this.session.role).is(Role.ADMIN)){
            return false;
        }

        let settings: Settings = await settingsService.getSettings();
        return settings.applicationBlocked && (<Role>this.session.role).is(Role.IMPLEMENTER);

    }

}
