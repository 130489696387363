import {restService} from "@/services";
import {Endpoints} from "@/endpoints";
import {User} from "@/models/User";
import {Role} from "@/models/enums/Role";
import {Page} from "@/models/Page";
import {SortAndFilterOptions} from "@/models/SortAndFilterOptions";
import {UpdateUserRequest} from "@/models/request/UpdateUserRequest";
import {CreateUserRequest} from "@/models/request/CreateUserRequest";
import {ChangePasswordRequest} from "@/models/request/ChangePasswordRequest";
import Implementer from "@/models/Implementer";

export default class UserService {

    transformSingle(user: User): User {
        user.role = Role.of(<string>user.role);
        return user;
    }

    transform(users: Page<User>): Page<User> {

        for (let user of users.content) {
            user = this.transformSingle(user);
        }

        return users;

    }

    async listImplementers(): Promise<Array<Implementer>> {
        return await restService.listSimple<Implementer>(Endpoints.getImplementers);
    }

    async list(sortAndFilterOptions: SortAndFilterOptions): Promise<Page<User>> {
        let users: Page<User> = await restService.list<User>(Endpoints.getUsers, sortAndFilterOptions);
        return this.transform(users);
    }

    async createUser(createUserRequest: CreateUserRequest): Promise<void> {
        await restService.post(Endpoints.createUser, createUserRequest);
    }

    async updateUser(id: number, updateUserRequest: UpdateUserRequest): Promise<void> {
        await restService.put(Endpoints.updateUser.replace(':id', `${id}`), updateUserRequest);
    }

    async changeUserPassword(id: number, changePasswordRequest: ChangePasswordRequest): Promise<void> {
        await restService.put(Endpoints.changeUserPassword.replace(':id', `${id}`), changePasswordRequest);
    }

    async usernameExists(username: string): Promise<boolean> {
        return (await restService.get(Endpoints.usernameExists.replace(':username', `${username}`)))['message']  === 'true';
    }

    async emailExists(email: string): Promise<boolean> {
        return (await restService.get(Endpoints.emailExists.replace(':email', `${email}`)))['message'] === 'true';
    }

}
