import {restService} from "@/services";
import {Endpoints} from "@/endpoints";
import {Settings} from "@/models/Settings";
import {UpdateSettingsRequest} from "@/models/request/UpdateSettingsRequest";

export class SettingsService {

    async getSettings(): Promise<Settings> {
        return restService.get<Settings>(Endpoints.getSettings);
    }

    async updateSettings(updateSettingsRequest: UpdateSettingsRequest): Promise<Settings> {
        return restService.put<Settings>(Endpoints.updateSettings, updateSettingsRequest);
    }

}
