import {AbstractEnum} from "@/models/enums/AbstractEnum";

export default class OrderStatus extends AbstractEnum {

    public static DRAFT  = new OrderStatus("DRAFT", "Projekt");
    public static SENT  = new OrderStatus("SENT", "Złożony");
    public static IN_PROGRESS  = new OrderStatus("IN_PROGRESS", "W realizacji");
    public static REJECTED  = new OrderStatus("REJECTED", "Odrzucony");
    public static END  = new OrderStatus("END", "Zakończony");

    static of(name: string): OrderStatus {
        return OrderStatus[name];
    }

    static isIn(list: Array<OrderStatus>, target: OrderStatus) : boolean {

        for(let item of list){
            if(item.name === target.name){
                return true;
            }
        }

        return false;

    }

}
