



















































import Vue from 'vue';
import UserSession from "@/models/UserSession";
import {profileService, sessionService} from "@/services";
import {Role} from "@/models/enums/Role";
import {EventBus} from "@/main";
import UserNotificationComponent from "@/components/UserNotification.vue";

class MenuItem {

  drawer: boolean = false;
  title: string;
  icon: string;
  link: string;
  action: Function;
  allowedRoles: Array<Role>;

  conditionsMet(session: UserSession): boolean {
    return Role.isIn(this.allowedRoles, session ? <Role>session.role : null);
  }

  static asLink(title: string, icon: string, link: string, allowedRoles: Array<Role>): MenuItem {
    const menuItem: MenuItem = new MenuItem();
    menuItem.title = title;
    menuItem.icon = icon;
    menuItem.link = link;
    menuItem.allowedRoles = allowedRoles;
    return menuItem;
  }

  static asAction(title: string, icon: string, action: Function, allowedRoles: Array<Role>): MenuItem {
    const menuItem: MenuItem = new MenuItem();
    menuItem.title = title;
    menuItem.icon = icon;
    menuItem.action = action;
    menuItem.allowedRoles = allowedRoles;
    return menuItem;
  }

}

export default Vue.extend({
  name: 'App',

  components: {
    UserNotificationComponent
  },

  data: () => ({
    profile: null,
    session: null,
    items: [
      MenuItem.asLink('Zamówienia', 'mdi-clipboard-multiple-outline', '/orders', [Role.ADMIN, Role.IMPLEMENTER]),
      MenuItem.asLink('Użytkownicy', 'mdi-account-multiple-outline', '/users', [Role.ADMIN]),
      MenuItem.asLink('Ustawienia', 'mdi-cog-outline', '/settings', [Role.ADMIN]),
      MenuItem.asLink('Moje konto', 'mdi-account-tie-outline', '/profile', [Role.ADMIN, Role.IMPLEMENTER]),
      MenuItem.asLink('Pomoc', 'mdi-help-circle-outline', '/help', [Role.ADMIN, Role.IMPLEMENTER]),
      MenuItem.asAction('Wyloguj', 'mdi-logout', sessionService.logout.bind(sessionService), [Role.ADMIN, Role.IMPLEMENTER])
    ] as Array<MenuItem>
  }),

  async created(): Promise<void> {
    EventBus.$on('sessionChange', () => {
      this.getSession();
      this.getProfile();
    })
    EventBus.$on('profileChange', () => {
      this.getSession();
      this.getProfile();
    })
    await this.getSession();
    await this.getProfile();
  },

  beforeDestroy(): void {
    EventBus.$off('sessionChange', this.getSession);
  },

  computed: {

    iconSize() {

      //@ts-ignore
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 20;
        case 'sm':
          return 20;
        case 'md':
          return 30;
        case 'lg':
          return 40;
        case 'xl':
          return 40;
      }

      return 40;

    }

  },

  methods: {

    async getSession(): Promise<void> {
      this.session = await sessionService.getSession();
    },

    async getProfile(): Promise<void> {
      this.profile = await profileService.getProfile();
    },

    itemAction(item: MenuItem): void {

      if (item.action) {
        item.action();
      }

    }

  }

});
